import { getAppToken, useAppURL, useConfig, useExtensions } from 'api'
import { useEffect, useRef, useState } from 'react'
import { getShopId, nanoId, showMessage, useWindowDimensions } from 'utils'
import { Appointment, Config, RegisteredExtension } from 'types'
import { VerticalContainer } from 'components/styled'
import { AdminHostAppointmentDetails, AppTarget } from '@sesamiapp/app-message'
import { theme } from 'antd'

type ExtensionLoaderProps = {
    appointment: Appointment
}

export const AppointmentDetailsInfoExtensionLoader = ({ appointment }: ExtensionLoaderProps) => {

    const { data: config } = useConfig()
    const { data: extensions } = useExtensions([AppTarget.ADMIN_APPOINTMENT_DETAILS], 15)

    return (config && extensions && extensions.length) ? (
        <VerticalContainer style={{ gap: 0 }}>
            {extensions.map(extension => (
                <AppointmentDetailsExtensionLoaderContent
                    key={extension.id}
                    extension={extension}
                    appointment={appointment}
                    config={config}
                />
            ))}
        </VerticalContainer>
    ) : null

}

type ExtensionLoaderContentProps = {
    extension: RegisteredExtension
    appointment: Appointment
    config: Config
}

const AppointmentDetailsExtensionLoaderContent = ({ extension, appointment, config }: ExtensionLoaderContentProps) => {

    //hooks:
    const { token: { paddingXS, marginMD }} = theme.useToken()
    const { SM } = useWindowDimensions()
    const iframe = useRef<HTMLIFrameElement>(null)

    //state:
    const [ timestamp ] = useState((new Date()).getTime())
    const [ messageId ] = useState(nanoId([extension.appId, timestamp, config.locale], 8))
    const [ client, setClient ] = useState<AdminHostAppointmentDetails | null>(null)
    const [ height, setHeight ] = useState(0)

    //api:
    const { data: url } = useAppURL(
        extension.appId,
        extension.id,
        AppTarget.ADMIN_APPOINTMENT_DETAILS,
        timestamp,
        messageId,
        config.locale,
        null
    )

    //startup:
    useEffect(() => {
        if(iframe.current && !client){
            const cl = new AdminHostAppointmentDetails({
                messageId,
                shopId: getShopId() ?? '',
                locale: config.locale,
                extra: { appointmentId: appointment.id },
                onInitEnded: () => {},
                getToken,
                onHeightChange: setHeight,
                onNotification: showMessage
            })
            setClient(cl)
        }
    }, [iframe])

    const getToken = async () => {
        const response: any = await getAppToken(extension.appId, extension.id)
        try{
            const token = response.data.createAccessToken.token
            return token
        }catch(e){e}
        return null
    }

    return (
        <div style={{
            height: height ? undefined : 0,
            padding: height ? `0 ${SM ? paddingXS : 0}px` : undefined,
            marginTop: height ? marginMD : undefined
        }}>
            <iframe
                ref={iframe}
                src={(client && url) ? url : undefined}
                style={{
                    width: '100%',
                    height: height,
                    border: 'none'
                }}
            />
        </div>
    )
}
