import { accessToken, env, getAccessTokenExpiration, queryClient, refreshToken, useAuthentication, useTranslate } from 'utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ACCESS_TOKEN_REFRESHING_TIMEOUT, CACHE_DEFAULT_STALE_TIME } from 'app.constants'
import { useEffect, useState } from 'react'
import request, { Variables, gql } from 'graphql-request'
import { message } from 'antd'
import { refreshAccessToken } from 'api'

//types:
type ApiPaginatedQueryHandlerParams<T> = {
    query: string
    variables?: Variables
    limit: number
    cacheKey: any[]
    noAuth?: boolean
    enabled?: boolean
    staleTime?: number
    refetchInterval?: number
    keepPreviousData?: boolean
    getResponse: (json: any) => any
    onSuccess: (response: any) => T
    onError?: (response: any) => T | void
    onForbidden?: (json: any) => void
    onUnauthorized?: (json: any) => void
    onNotFound?: (json: any) => void
    onUnknownError?: (json: any) => void
}
type ApiQueryHandlerParams<T> = Omit<ApiPaginatedQueryHandlerParams<T>, 'getResponse' | 'search' | 'limit'>
type ApiMutationHandlerParams<T> = Omit<ApiQueryHandlerParams<T>, 'cacheKey' | 'search' | 'limit' | 'enabled'>

type ApiHandlerParams<T> = ApiQueryHandlerParams<T> | ApiMutationHandlerParams<T> | ApiPaginatedQueryHandlerParams<T>

export const useQueryHandler = <T>(params: ApiQueryHandlerParams<T>) => useApiQuery(useAddDefaultHandlers<ApiQueryHandlerParams<T>>(params))
export const useMutationHandler = <T>(params: ApiMutationHandlerParams<T>) => useApiMutation(useAddDefaultHandlers<ApiMutationHandlerParams<T>>(params))
export const usePaginationHandler = <T>(params: ApiPaginatedQueryHandlerParams<T>) => useApiPagination(useAddDefaultHandlers<ApiPaginatedQueryHandlerParams<T>>(params))

//useAddDefaultHandlers:
const useAddDefaultHandlers = <T>(params: any): T => {

    //hooks:
    const { __ } = useTranslate()
    const { logout } = useAuthentication()

    return {
        ...params,
        onForbidden: params.onForbidden ? params.onForbidden : () => {
            message.error(__`forbidden_access_denied`)
        },
        onUnauthorized: params.onUnauthorized ? params.onUnauthorized : () => {
            logout()
            if(params.cacheKey){
                queryClient.removeQueries(params.cacheKey)
            }
        },
        onNotFound: params.onNotFound ? params.onNotFound : () => {
            message.error(__`oops_something_went_wrong`)
            if(params.cacheKey){
                queryClient.removeQueries()
            }
        },
        onUnknownError: params.onUnknownError ? params.onUnknownError : () => {
            message.error(__`oops_something_went_wrong`)
            if(params.cacheKey){
                queryClient.removeQueries()
            }
        }
    }

}

//useApiQuery:
export const useApiQuery = <T>(params: ApiQueryHandlerParams<T>) => {
    const { isLoggedIn, logout } = useAuthentication()
    return useQuery(
        params.cacheKey,
        async () => handleResponse(params, await requestAPI(params.query, isLoggedIn, logout, params.noAuth, params.variables)),
        {
            enabled: params.enabled,
            staleTime: params.staleTime ?? CACHE_DEFAULT_STALE_TIME,
            refetchInterval: params.refetchInterval,
            keepPreviousData: params.keepPreviousData
        }
    )
}

//useApiMutation:
export const useApiMutation = <T>(params: ApiMutationHandlerParams<T>) => {
    const { isLoggedIn, logout } = useAuthentication()
    return useMutation(async () => handleResponse(params, await requestAPI(params.query, isLoggedIn, logout, params.noAuth, params.variables)))
}

//useApiPagination:
export const useApiPagination = <T>(params: ApiPaginatedQueryHandlerParams<T>) => {

    //auth:
    const { isLoggedIn, logout } = useAuthentication()

    //state:
    const [ nextCursor              , setNextCursor              ] = useState(params.variables?.after ?? null as string | null)
    const [ previousCursor          , setPreviousCursor          ] = useState(params.variables?.before ?? null as string | null)
    const [ nextQueryNextCursor     , setNextQueryNextCursor     ] = useState(null as string | null)
    const [ nextQueryPreviousCursor , setNextQueryPreviousCursor ] = useState(null as string | null)
    const [ total                   , setTotal                   ] = useState(0)
    const [ remaining               , setRemaining               ] = useState(0)
    const [ pageNum                 , setPageNum                 ] = useState(0)
    const [ isNextDisabled          , setIsNextDisabled          ] = useState(true)
    const [ isPreviousDisabled      , setIsPreviousDisabled      ] = useState(true)

    //useQuery:
    const useQ = useQuery(
        [...params.cacheKey, params.variables, nextCursor, previousCursor],
        async () => handlePaginatedResponse(
            params,
            await requestAPI(params.query, isLoggedIn, logout, params.noAuth, {
                ...params.variables,
                after: nextCursor,
                before: previousCursor
            })
        ),
        {
            enabled: params.enabled,
            staleTime: params.staleTime ?? CACHE_DEFAULT_STALE_TIME,
            refetchInterval: params.refetchInterval,
            keepPreviousData : params.keepPreviousData ?? true
        }
    )
    const { data: response } = useQ

    //after fetch:
    useEffect(() => {
        if(response?.data){
            setNextQueryNextCursor(response.nextCursor)
            setNextQueryPreviousCursor(response.previousCursor)

            setTotal(response.totalItems)
            setRemaining(response.remainingItems)
            setPageNum(Math.ceil((response.totalItems - response.remainingItems)/params.limit))
            
            setIsNextDisabled(response.remainingItems === 0)
            setIsPreviousDisabled(response.remainingItems + params.limit >= response.totalItems)
        }
    }, [response])

    //fetchNext:
    const fetchNext = () => {
        if(!isNextDisabled){
            setNextCursor(nextQueryNextCursor)
            setPreviousCursor(null)
        }
    }

    //fetchPrevious:
    const fetchPrevious = () => {
        if(!isPreviousDisabled){
            setNextCursor(null)
            setPreviousCursor(nextQueryPreviousCursor)
        }
    }

    return {
        ...useQ,
        data: response?.data,
        total,
        remaining,
        pageNum: pageNum,
        totalPage: Math.ceil(total/(params.limit)),
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    }

}

//requestAPI:
export const requestAPI = async (
    query: string,
    isLoggedIn: boolean,
    logout: () => void,
    noAuth?: boolean,
    variables?: Variables
) => {
    if(noAuth || !isLoggedIn){
        return await requestFetchAPI(
            { 'Content-Type': 'application/json' },
            query,
            variables
        )
    }else{
        await ensureAccessTokenIsValid(logout)
        return await requestFetchAPI(
            {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${accessToken()}`
            },
            query,
            variables
        )
    }
}

//ensureAccessTokenIsValid:
export const ensureAccessTokenIsValid = (logout: () => void) => {
    const start = Date.now()
    const waitForAccessToken = async (resolve: any, reject: any) => {
        if(refreshToken()){
            const AT = accessToken()
            if(!AT || (AT && isAccessTokenExpired(AT))){
                if(window.isAccessTokenRefreshing === true){
                    if(Date.now() - start >= ACCESS_TOKEN_REFRESHING_TIMEOUT){
                        reject(new Error('timeout'))
                    }else{
                        setTimeout(waitForAccessToken.bind(this, resolve, reject), 50)
                    }
                }else{
                    const newAccessToken = await refreshAccessToken(logout)
                    if(newAccessToken){
                        resolve(newAccessToken)
                    }else{
                        reject(new Error('error fetching access token'))
                    }
                }
            }else{
                resolve(AT)
            }
        }else{
            reject(new Error('logout'))
        }
    }
    return new Promise(waitForAccessToken)
}

const isAccessTokenExpired = (accessToken: string) => {
    const now = new Date()
    if((getAccessTokenExpiration(accessToken) - 30)*1000 > now.getTime()){ // 30 seconds offset
        return false
    }else{
        return true
    }
}

//fetchAPI:
const requestFetchAPI = async (
    headers: any,
    query: string,
    variables?: Variables,
) => {
    let data = null, errors = null
    try{
        data = await <any>request({
            url: env().GRAPHQL_API_BASE_URL,
            requestHeaders: headers,
            document: gql`${query}`,
            variables
        }) ?? null
    }catch(res: any){
        errors = await res.response.errors
    }
    return { data, errors }
}

//handleResponse:
const handleResponse = <T>(params: ApiHandlerParams<T>, json: any) => {

    let response: undefined | null | false | T = false
    try{
        if(getErrorName(json) === 'forbidden'){
            response = null
            params.onError?.(json)
        }else{
            if(params.onSuccess && json.data){
                response = params.onSuccess(json)
            }
            if(params.onError && json.errors){
                response = params.onError(json) ?? false
            }
        }
    }catch(err: any){err}

    callErrorHandlers(response, json, params)
    
    return response

}

//handlePaginatedResponse:
const handlePaginatedResponse = <T>(params: ApiPaginatedQueryHandlerParams<T>, json: any) => {

    let returnValue: {
        data: T | null | false
        totalItems: number
        remainingItems: number
        nextCursor: string | null
        previousCursor: string | null
    } = {
        data: false,
        totalItems: 0,
        remainingItems: 0,
        nextCursor: null,
        previousCursor: null
    }

    try{
        if(getErrorName(json) === 'forbidden'){
            returnValue = { ...returnValue, data: null }
            params.onError?.(json)
        }else{
            const response = params.getResponse(json)
            if(params.onSuccess && response){
                returnValue = {
                    data: params.onSuccess(response),
                    totalItems: response.totalItems,
                    remainingItems: response.remainingItems,
                    nextCursor: response.nextCursor,
                    previousCursor: response.previousCursor
                }
            }
            if(params.onError && json.errors){
                returnValue = { ...returnValue, data: params.onError(json) ?? false }
            }
        }
    }catch(err: any){err}

    callErrorHandlers(returnValue.data, json, params)

    return { ...returnValue }

}

//callErrorHandlers:
const callErrorHandlers = <T>(response: T | null | false, json: any, params: ApiHandlerParams<T>) => {
    if(response === null){ // forbidden
        params.onForbidden?.(json)
    }else if(response === false){ // unknown error
        switch(getErrorName(json)){
            case 'unauthorized':
                params.onUnauthorized?.(json)
                break
            case 'not_found':
                params.onNotFound?.(json)
                break
            default:
                params.onUnknownError?.(json)
        }
    }
}

export const getErrorName = (json: any) => json.errors?.[0].extensions?.errors?.[0].constraints?.[0].name
